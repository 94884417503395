/* eslint-disable no-console */
import {
  ApplicationInsights,
  IConfig,
  IConfiguration,
} from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import {
  APPLICATIONINSIGHTS_CONNECTION_STRING,
  isBrowser,
  isProduction,
} from "appConfiguration";

export const reactPlugin = new ReactPlugin();
const appInsightsConfig: IConfiguration & IConfig = {
  connectionString: APPLICATIONINSIGHTS_CONNECTION_STRING,
  enableAutoRouteTracking: true,
  extensions: [reactPlugin],
};
export const appInsights = new ApplicationInsights({
  config: appInsightsConfig,
});
if (isBrowser && isProduction) {
  try {
    appInsights.loadAppInsights();
  } catch (e) {
    console.log({ appInsightsConfig });
    console.error("failed to load appInsights");
  }
}
