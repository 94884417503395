import { ALL_VALUE } from "appConfiguration";
import { DocDocumentCreateUpdateDto } from "features/DMS/entities";
import { ViewablePermissionsFormValues } from "../defs";

function getCorporatePermissionsSet({
  brands,
  regions,
  departments,
  corporateRoles,
  toggle,
}: Partial<ViewablePermissionsFormValues>): DocDocumentCreateUpdateDto["permissions"] {
  // handle expanding and filtering department permissions
  if (toggle === "departments") {
    const departmentPerms: DocDocumentCreateUpdateDto["permissions"] = [];
    // expand into all the combinations of department, region, and brand
    departments?.forEach((currentDepartment) => {
      regions?.forEach((currentRegion) => {
        brands?.forEach((currentBrand) => {
          const currentPermission: DocDocumentCreateUpdateDto["permissions"][number] =
            {
              brand: currentBrand,
              region: currentRegion,
              departmentName: currentDepartment,
            };
          departmentPerms.push(currentPermission);
        });
      });
    });
    return departmentPerms;
  }
  const rolePermissions: DocDocumentCreateUpdateDto["permissions"] = [];
  corporateRoles?.forEach((currentRole) => {
    regions?.forEach((currentRegion) => {
      brands?.forEach((currentBrand) => {
        const currentRolePermission: DocDocumentCreateUpdateDto["permissions"][number] =
          {
            brand: currentBrand,
            region: currentRegion,
            roleId: currentRole,
          };
        rolePermissions.push(currentRolePermission);
      });
    });
  });
  return rolePermissions;
}

function getShopPermissionsSet({
  brands,
  regions,
  shops,
  shopRoles,
}: Partial<ViewablePermissionsFormValues>): DocDocumentCreateUpdateDto["permissions"] {
  const shopPerms: DocDocumentCreateUpdateDto["permissions"] = [];
  const isAllShops = shops?.includes(ALL_VALUE);
  if (isAllShops) {
    shopRoles?.forEach((currentShopRole) => {
      regions?.forEach((currentRegion) => {
        brands?.forEach((currentBrand) => {
          shopPerms.push({
            brand: currentBrand,
            region: currentRegion,
            shopId: ALL_VALUE,
            shopRoleId: currentShopRole,
          });
        });
      });
    });
    return shopPerms;
  }
  shops?.forEach((currentShop) => {
    shopRoles?.forEach((currentShopRole) => {
      shopPerms.push({
        brand: ALL_VALUE,
        region: ALL_VALUE,
        shopId: currentShop,
        shopRoleId: currentShopRole,
      });
    });
  });
  return shopPerms;
}

export function formStateToPermissionSet({
  brands,
  corporateRoles,
  departments,
  regions,
  shopRoles,
  shops,
  toggle,
  isVisibleToShops,
}: ViewablePermissionsFormValues): DocDocumentCreateUpdateDto["permissions"] {
  if (brands.length === 0 || regions.length === 0) {
    return [];
  }
  if (departments.length === 0 && corporateRoles.length === 0) {
    return [];
  }

  const corporatePerms = getCorporatePermissionsSet({
    brands,
    regions,
    departments,
    corporateRoles,
    toggle,
  });
  const shopPerms = isVisibleToShops
    ? getShopPermissionsSet({ brands, regions, shopRoles, shops, toggle })
    : [];
  return [...corporatePerms, ...shopPerms];
}
