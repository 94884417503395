import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ALL_VALUE } from "appConfiguration";
import { PublicShopDto, SelectOption } from "core/entities";
import { usePublicShops } from "./usePublicShops";

/**
 * Custom hook that fetches public shop options and returns the options, loading state, error state, and fetched data.
 *
 *
 * @param filter - Optional filter object to apply when fetching public shops.
 * @default filter.MaxResultCount = 1000
 * @param shouldFetch - Boolean indicating whether to fetch the data immediately or not. Defaults to true.
 * @param swrOptions - Optional options object to pass to the underlying SWR hook.
 * @param includeAllOption - Boolean indicating whether to include an "All" option in the options list. Defaults to false.
 * @param getLabel - Optional function to generate the label for each shop. Defaults to the shop's name.
 * @returns An object containing the options, loading state, error state, and fetched data.
 */
export function usePublicShopOptions({
  filter,
  shouldFetch = true,
  fetchAll = true,
  swrOptions,
  includeAllOption,
  getLabel,
}: Parameters<typeof usePublicShops>[0] & {
  includeAllOption?: boolean;
  getLabel?: (shop: PublicShopDto) => string;
}) {
  const [staticGetLabel] = useState(() => getLabel);
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:useSharedEntitiesShopsOptions",
  });
  const { data, isLoading, isError } = usePublicShops({
    filter,
    shouldFetch,
    fetchAll,
    swrOptions,
  });
  const options = useMemo(() => {
    const potentialOptions =
      data?.items
        ?.map((shopInfo) => ({
          label:
            typeof staticGetLabel === "function"
              ? staticGetLabel(shopInfo)
              : shopInfo.name,
          value: shopInfo.id,
        }))
        ?.filter(
          (option): option is SelectOption =>
            typeof option.label === "string" &&
            option.label.length > 0 &&
            typeof option.value === "string" &&
            option.value.length > 0
        )
        .sort((a, b) => (a.label > b.label ? 1 : -1)) ?? [];
    if (includeAllOption) {
      potentialOptions.unshift({ label: t("allShops"), value: ALL_VALUE });
    }
    return potentialOptions;
  }, [data?.items, includeAllOption, staticGetLabel, t]);
  return { options, isLoading, isError, data };
}
