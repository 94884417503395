import { FixHubPermissions } from "resources/permissions";
import { useUserPermissionContextsInCurrentBrandRegion } from "./useUserPermissionContextsInCurrentBrandRegion";

export function usePermission(policyKey: FixHubPermissions) {
  const { contexts } = useUserPermissionContextsInCurrentBrandRegion();
  if (policyKey.length === 0) {
    return true;
  }
  if (!contexts) {
    return false;
  }
  const permissions = new Set(
    contexts.flatMap((permContext) => permContext.permissions)
  );

  return permissions.has(policyKey);
}
