import { SeverityLevel } from "@microsoft/applicationinsights-web";
import {
  BodyProperty,
  CentrixPathsByMethod,
  RequestBodyContentType,
  JsonProperty,
  HttpRequestMethod,
  ParametersProperty,
  RemoteServiceErrorResponse,
} from "core/entities/APITypes";
import { buildCentrixUrl } from "core/utils/buildCentrixUrl";
import { objectToFormData } from "core/utils/objectToFormData";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { useAPIHeaders } from "./useAPIHeaders";
import { useTrackException } from "./useTrackException";

type FailureResponse = Omit<Response, "json" | "ok"> & {
  ok: false;
  json: () => Promise<RemoteServiceErrorResponse>;
};

export type SuccessResponse<
  P extends CentrixPathsByMethod<M>,
  M extends HttpRequestMethod = any,
> = Omit<Response, "json" | "ok"> & {
  ok: true;
} & JsonProperty<P, M>;

export type CentrixFetchResponse<
  P extends CentrixPathsByMethod<M>,
  M extends HttpRequestMethod = any,
> = SuccessResponse<P, M> | FailureResponse;

const CONTENT_TYPE_SERIALIZERS: Record<
  RequestBodyContentType,
  (body: any) => any
> = {
  "application/json": JSON.stringify,
  "application/merge-patch+json": JSON.stringify,
  "multipart/form-data": objectToFormData,
};
export type CentrixFetchArgs<
  P extends CentrixPathsByMethod<M>,
  M extends HttpRequestMethod = any,
  C extends RequestBodyContentType = "application/json",
> = {
  method: M;
  path: P;
  contentType?: C;
} & ParametersProperty<P, M> &
  BodyProperty<P, M, C>;

function centrixFetch<
  P extends CentrixPathsByMethod<M>,
  M extends HttpRequestMethod,
  C extends RequestBodyContentType = "application/json",
>(
  {
    method,
    body,
    path,
    parameters,
    contentType = "application/json" as C,
  }: CentrixFetchArgs<P, M, C>,
  locale?: string,
  options?: RequestInit
): Promise<CentrixFetchResponse<P, M>> {
  const url = buildCentrixUrl({ path, parameters, locale });

  const headers: Record<string, any> = { ...options?.headers };
  if (
    body &&
    !("Content-Type" in headers) &&
    contentType !== "multipart/form-data" // "multipart/form-data" sets its own content type.
  ) {
    headers["Content-Type"] = contentType;
  }
  const bodySerializer = CONTENT_TYPE_SERIALIZERS[contentType];

  return fetch(url, {
    method: method.toUpperCase(),
    body: body ? bodySerializer(body) : undefined,
    headers,
  }) as Promise<CentrixFetchResponse<P, M>>;
}

const CENTRIX_FETCH_ERROR_STATUS_TEXT = "Fetch threw an error";

const centrixFetchError: RemoteServiceErrorResponse = {
  error: {
    code: "500",
    message: "Unable to establish a connection to the server",
  },
};

export const deserializationError: RemoteServiceErrorResponse = {
  error: {
    message: "Failed to deserialize response",
  },
};

const centrixFetchErrorResponseBody = JSON.stringify(centrixFetchError);

function getCentrixFetchErrorResponse(): FailureResponse {
  return new Response(centrixFetchErrorResponseBody, {
    status: 500,
    statusText: CENTRIX_FETCH_ERROR_STATUS_TEXT,
    headers: { "Content-Type": "application/json" },
  }) as FailureResponse;
}

/**
 * General function for performing http actions with Centrix. Provides autocomplete for paths and methods
 * as well as typed responses available through the `json` method.
 * @param trackFailure if true, track exceptions when fetch fails. Default is true.
 * @returns a function that performs a fetch request with Centrix
 *
 * @example
 * ```ts
 * const centrixFetch = useCentrixFetch();
 * const response = await centrixFetch({
 *  method: "post",
 *  path: "/api/app/estimate-request/{id}/preview-email",
 *  body: {
 *   fistName: "John",
 *   lastName: "Doe"
 *  });
 * if (!response.ok) {
 *   const errorData = await response.json(); // errorData is of type RemoteServiceErrorResponse
 *  console.log(errorData);
 * return;
 * }
 * const data = await response.json(); // data is of type EstimateRequestEmailPreviewDto
 * console.log(data);
 * successToast("Email sent successfully");
 * ```
 *
 */
export function useCentrixFetch(trackFailure = true) {
  const headers = useAPIHeaders();
  const trackException = useTrackException();
  const { locale } = useRouter();

  return useCallback(
    async <
      P extends CentrixPathsByMethod<M>,
      M extends HttpRequestMethod,
      C extends RequestBodyContentType = "application/json",
    >(
      centrixArgs: CentrixFetchArgs<P, M, C>,
      options?: RequestInit
    ): Promise<CentrixFetchResponse<P, M>> => {
      const fetchOptions = {
        ...options,
        headers: { ...headers, ...options?.headers },
      };

      const res = await centrixFetch(centrixArgs, locale, fetchOptions).catch(
        (e) => {
          if (trackFailure) {
            trackException({
              exception: e,
              severityLevel: SeverityLevel.Error,
              properties: { ...centrixArgs },
            });
          }
          return getCentrixFetchErrorResponse();
        }
      );
      if (
        !res.ok &&
        trackFailure &&
        res.statusText !== CENTRIX_FETCH_ERROR_STATUS_TEXT // track exception already handles in catch block
      ) {
        trackException({
          exception: new Error(`Failed to fetch ${centrixArgs.path}`),
          severityLevel: SeverityLevel.Error,
          properties: {
            ...centrixArgs,
            status: res.status,
            statusText: res.statusText,
          },
        });
      }
      return res;
    },
    [headers, locale, trackException, trackFailure]
  );
}

/**
 * Version of useCentrixFetch that does not include headers. Useful for public endpoints
 * General function for performing http actions with Centrix. Provides autocomplete for paths and methods
 * @param trackFailure if true, track exceptions when fetch fails. Default is true.
 * @returns
 */
export function useCentrixFetchPublic(trackFailure = true) {
  const trackException = useTrackException();
  const { locale } = useRouter();
  return useCallback(
    async <
      P extends CentrixPathsByMethod<M>,
      M extends HttpRequestMethod,
      C extends RequestBodyContentType = "application/json",
    >(
      centrixArgs: CentrixFetchArgs<P, M, C>
    ): Promise<CentrixFetchResponse<P, M>> => {
      const res = await centrixFetch(centrixArgs, locale).catch((e) => {
        if (trackFailure) {
          trackException({
            exception: e,
            severityLevel: SeverityLevel.Error,
            properties: { ...centrixArgs },
          });
        }
        return getCentrixFetchErrorResponse();
      });
      if (
        !res.ok &&
        trackFailure &&
        res.statusText !== CENTRIX_FETCH_ERROR_STATUS_TEXT // track exception already handles in catch block
      ) {
        trackException({
          exception: new Error(`Failed to fetch ${centrixArgs.path}`),
          severityLevel: SeverityLevel.Error,
          properties: {
            ...centrixArgs,
            status: res.status,
            statusText: res.statusText,
          },
        });
      }
      return res;
    },
    [locale, trackException, trackFailure]
  );
}
