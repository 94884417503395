import { ReactHookTagInput } from "core/components/ReactHookFormComponents/ReactHookTagInput";
import { ReactHookTextField } from "core/components/ReactHookFormComponents/ReactHookTextField";
import { useState, useLayoutEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { useTranslationFields } from "./useTranslationFields";
import { DMSLanguageTabs } from "./DMSLanguageTabs";
import { determineSlideDirection } from "./determineSlideDirection";

export interface ContainerTranslationsFieldsProps {
  formType?: "LibraryForm" | "CategoryForm" | "SubCategoryForm";
}

/**
 * Adds { translations: IDocTranslationDto[] } to the current react-hook-form context
 * @param formType - the type of the parent form. Used to name translation keys
 */
export function ContainerTranslationsFields({
  formType = "LibraryForm",
}: ContainerTranslationsFieldsProps) {
  const { t } = useTranslation("DocumentManagementSystem");
  const { register } = useFormContext();
  const {
    currentLanguages: languages,
    selectedLanguage,
    previousLanguage,
    languageTabsProps,
  } = useTranslationFields("container");
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerEl, setContainerEl] = useState<HTMLDivElement | null>(null);
  useLayoutEffect(() => {
    setContainerEl(containerRef.current);
  }, []);

  return (
    <Stack spacing={2}>
      <DMSLanguageTabs {...languageTabsProps} />
      <Box
        sx={{
          display: "grid",
          gridTemplateAreas: "'stack'",
          overflowX: "clip",
        }}
      >
        {languages.map((transKey, index) => (
          <Slide
            key={transKey}
            appear={index !== 0}
            in={selectedLanguage === transKey}
            container={containerEl}
            style={{ gridArea: "stack" }}
            direction={determineSlideDirection(
              index,
              languages,
              previousLanguage,
              selectedLanguage
            )}
          >
            <Stack spacing={2}>
              <input
                type="hidden"
                {...register(`translations.${transKey}.transKey`, {
                  value: transKey,
                })}
              />
              <ReactHookTextField
                fullWidth
                required
                name={`translations.${transKey}.name`}
                label={t(`Next:DocumentManagementSystem:${formType}.name`, {
                  locale: transKey,
                })}
              />
              <ReactHookTextField
                fullWidth
                multiline
                minRows={6}
                name={`translations.${transKey}.description`}
                label={t(
                  `Next:DocumentManagementSystem:${formType}.description`,
                  { locale: transKey }
                )}
                tOptions={{ locale: transKey }}
              />
              <ReactHookTagInput
                fullWidth
                name={`translations.${transKey}.tags`}
                label={t(`Next:DocumentManagementSystem:${formType}.tags`, {
                  locale: transKey,
                })}
              />
            </Stack>
          </Slide>
        ))}
      </Box>
    </Stack>
  );
}
