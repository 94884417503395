import React from "react";
import MenuItem from "@mui/material/MenuItem";

export function AvailableRegionMenuItem({
  selected,
  onClick,
  label,
}: {
  label: string;
  selected: boolean;
  onClick?:
    | (React.MouseEventHandler<HTMLAnchorElement> &
        React.MouseEventHandler<HTMLSpanElement>)
    | undefined;
}) {
  return (
    <MenuItem
      sx={{ color: "text.primary" }}
      selected={selected}
      onClick={onClick}
    >
      {label}
    </MenuItem>
  );
}
