import { useUserPermissionContextsInCurrentBrandRegion } from "./useUserPermissionContextsInCurrentBrandRegion";

export function useDoesUserExistInCurrentContext() {
  const { contexts, isError, isLoading } =
    useUserPermissionContextsInCurrentBrandRegion();
  return {
    userExists: Boolean(
      contexts && Array.isArray(contexts) && contexts.length > 0
    ),
    isLoading,
    isError,
  };
}
