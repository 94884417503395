import CancelIcon from "@mui/icons-material/Cancel";
import Button, { ButtonProps } from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { CustomLink } from "./CustomLink";

export function CancelButton({ children, ...props }: ButtonProps) {
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:CancelButton",
  });
  return (
    <Button
      variant="outlined"
      color="warning"
      startIcon={<CancelIcon />}
      LinkComponent={CustomLink}
      {...props}
    >
      {children || t("buttonText")}
    </Button>
  );
}
