import "mock-service-worker/initMSW";
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from "@microsoft/applicationinsights-react-js";
import { ApplicationErrorBoundaryPage } from "core/pages/ApplicationErrorBoundaryPage";
import { reactPlugin } from "appInsightsWeb";
import React, { Suspense } from "react";
import Head from "next/head";
import { MUIThemeAndLocalizationProvider } from "core/components/MUIThemeAndLocalizationProvider";
import { ConfigCheckWrapper } from "core/components/ConfigCheckWrapper";
import { DatePickerLocalizationProvider } from "core/components/DatePickerLocalizationProvider";
import type { BrandCode, RegionCode } from "core/entities";
import { ApplicationLoadingDisplay } from "core/components/ApplicationLoadingDisplay";
import { AvailableRegionsContextProvider } from "core/context/AvailableRegionsContext";
import { Toaster } from "core/components/Toaster";
import { Box, ThemeOptions } from "@mui/material";
import { NuqsAdapter } from "nuqs/adapters/next/pages";
import CssBaseline from "@mui/material/CssBaseline";

/**
 * SharedWrapper component provides a common wrapper for the application,
 * handling global configurations and providers such as:
 * - AppInsights error boundary and context
 * - MUI theme and localization
 * - Date picker localization
 * - Available regions context
 * - Global styles and CSS baseline
 * - Toaster for notifications
 * - Configuration checks
 *
 * @param children - The child components to be wrapped
 * @param brandCode - The brand code for theming and configuration
 * @param availableRegions - Array of available region codes
 * @param customTheme - Optional custom MUI theme options
 */
export function SharedAppWrapper({
  children,
  brandCode,
  availableRegions = [],
  customTheme,
}: {
  children: React.ReactNode;
  brandCode?: BrandCode | undefined | null;
  availableRegions?: RegionCode[];
  customTheme?: ThemeOptions | null;
}) {
  return (
    <Suspense
      // Suspense is triggered when loading a translation resource
      fallback={
        <MUIThemeAndLocalizationProvider brandCode={brandCode || "fixaut"}>
          <ApplicationLoadingDisplay />
        </MUIThemeAndLocalizationProvider>
      }
    >
      {brandCode && (
        <Head>
          <link
            rel="icon"
            href={`/favicons/${brandCode}.png`}
            type="image/png"
          />
        </Head>
      )}
      <NuqsAdapter>
        <AvailableRegionsContextProvider availableRegions={availableRegions}>
          <ConfigCheckWrapper>
            <AppInsightsContext.Provider value={reactPlugin}>
              <AppInsightsErrorBoundary
                appInsights={reactPlugin}
                onError={ApplicationErrorBoundaryPage}
              >
                <MUIThemeAndLocalizationProvider
                  brandCode={brandCode ?? "fixaut"}
                  customTheme={customTheme}
                >
                  <CssBaseline />
                  <DatePickerLocalizationProvider>
                    <Toaster>
                      <Box
                        sx={{
                          backgroundColor: "background.default",
                          minHeight: "100vh",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {children}
                      </Box>
                    </Toaster>
                  </DatePickerLocalizationProvider>
                </MUIThemeAndLocalizationProvider>
              </AppInsightsErrorBoundary>
            </AppInsightsContext.Provider>
          </ConfigCheckWrapper>
        </AvailableRegionsContextProvider>
      </NuqsAdapter>
    </Suspense>
  );
}
