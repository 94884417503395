import { useMemo, useState } from "react";
import { useIntlFormatterLocales } from "./useIntlFormatterLocales";
import { usePreferredTemperatureUnit } from "./usePreferredTemperatureUnit";

export function useFormatTemperature(options?: Intl.NumberFormatOptions) {
  const locales = useIntlFormatterLocales();
  const [initialOptions] = useState(options);
  const { value: tempUnit } = usePreferredTemperatureUnit();
  return useMemo(
    () =>
      new Intl.NumberFormat(locales, {
        style: "unit",
        unit: tempUnit === "C" ? "celsius" : "fahrenheit",
        ...initialOptions,
      }).format,
    [initialOptions, locales, tempUnit]
  );
}
