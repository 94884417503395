import { useDoesUserExistInCurrentContext } from "core/hooks/useDoesUserExistInCurrentContext";
import { useExtensoToken } from "core/hooks/useExtensoToken";
import { UnauthorizedContextPage } from "core/pages/UnauthorizedContextPage";
import { useRouter } from "next/router";
import { ReactNode } from "react";
import { AvailableRoutes } from "resources/availableRoutes";
import { ApplicationErrorBoundaryPage } from "core/pages/ApplicationErrorBoundaryPage";
import { ApplicationLoadingDisplay } from "../ApplicationLoadingDisplay";
import { ErrorComponent } from "../ErrorComponent";
import { ExtensoLayout } from "../Layout/ExtensoLayout";
import { UnauthorizedLayout } from "../Layout/UnauthorizedLayout";

/**
 * This component is responsible for checking that the user's permission context contains the application's current brand/region context
 * If the user exists, it will display the children
 * If the user does not exist, it will display the UnauthorizedContextPage
 */
export function ContextVerifier({ children }: { children: ReactNode }) {
  const { pathname } = useRouter();
  const { isLoading, isError, userExists } = useDoesUserExistInCurrentContext();

  const { token: extensoToken } = useExtensoToken();
  const extensoRoute: AvailableRoutes = "/extenso-redirect";
  const isExtensoRoute = pathname === extensoRoute;
  const isExtenso = isExtensoRoute || Boolean(extensoToken);

  if (isLoading) {
    return <ApplicationLoadingDisplay />;
  }
  if (isError) {
    return (
      <ApplicationErrorBoundaryPage>
        <ErrorComponent apiError={isError} />
      </ApplicationErrorBoundaryPage>
    );
  }
  if (!userExists) {
    const Layout = isExtenso ? ExtensoLayout : UnauthorizedLayout;
    return (
      <Layout>
        <UnauthorizedContextPage />
      </Layout>
    );
  }
  return children;
}
