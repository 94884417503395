import { useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { APIError, GenericAPIData } from "core/entities";
import { useBrandRegionCode } from "core/hooks/useBrandRegionCode";
import { KeyedMutator } from "swr";
import { buildQueryString } from "core/utils/buildQueryString";
import { getBrandCode } from "core/utils/getBrandCode";
import { usePromiseCallbackTracking } from "core/hooks/usePromiseCallbackTracking";
import { useCentrixFetch } from "core/hooks/useCentrixFetch";
import { useCentrixApi } from "core/hooks/useCentrixApi";
import { useRouter } from "next/router";
import type { Notification } from "./NotificationDefs";

type NotificationEndpointResponseJson = {
  totalCount: number;
  totalUnseenCount: number;
  totalUnreadCount: number;
  items: Notification[];
};

interface UseNotificationsReturn {
  isLoading: boolean;
  isError: APIError | undefined;
  data: GenericAPIData<Notification> | undefined;
  totalCount: number;
  totalUnreadCount: number;
  totalUnseenCount: number;
  mutate: KeyedMutator<NotificationEndpointResponseJson>;
  markNotificationAsRead: (id: string) => Promise<void>;
  markNotificationAsUnread: (id: string) => Promise<void>;
  markAllNotificationsAsRead: () => Promise<void>;
  markAllNotificationsAsSeen: () => Promise<void>;
  markNotificationAsReadByItemId: (itemId: string) => Promise<void>;
  deleteNotification: (id: string) => Promise<void>;
  deleteAllNotifications: () => Promise<void>;
  shouldEntityHaveNotificationDotById: (id: string) => boolean;
  hasUnreadNotification: (id: string) => boolean;
  hasUnreadNotificationChildren: (id: string) => boolean;
  notifications: Notification[];
}

const FIVE_MINUTES_IN_MILLISECONDS = 1000 * 60 * 5;

export function useNotifications(): UseNotificationsReturn {
  const { locale } = useRouter();
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:useNotifications",
  });
  const brandCode = getBrandCode();
  const regionCode = useBrandRegionCode();
  const {
    data: apiData,
    isError,
    isLoading,
    mutate,
  } = useCentrixApi({
    path: "/api/app/notifications",
    parameters: { query: { Locale: locale } },
    swrOptions: {
      refreshInterval: FIVE_MINUTES_IN_MILLISECONDS,
      revalidateIfStale: false,
    },
  });
  const data = apiData as NotificationEndpointResponseJson; // TODO: Fix this type assertion
  const centrixFetch = useCentrixFetch();

  const notifications = useMemo(() => {
    if (!data || !Array.isArray(data.items)) {
      return [];
    }
    return data.items.map((item) => {
      if (item.itemType !== "DmsDocument") {
        return item;
      }
      if (!("dynamicData" in item)) {
        return item;
      }
      if (item?.dynamicData?.type !== "file") {
        return item;
      }
      const { filename, fileId } = item.dynamicData;
      const queryString = buildQueryString({
        fileId,
        brandCode,
        regionCode,
        locale,
      });
      const uri = `/api/dms-document/${encodeURIComponent(
        filename
      )}?${queryString}`;
      return { ...item, uri };
    });
  }, [data, brandCode, regionCode, locale]);

  const unreadNotificationParentIds = useMemo(() => {
    const ids = notifications.reduce((acc, currentNotification) => {
      if (currentNotification.isRead) {
        return acc;
      }
      if (!("dynamicData" in currentNotification)) {
        return acc;
      }
      if (!currentNotification.dynamicData) {
        return acc;
      }
      if (!("parents" in currentNotification.dynamicData)) {
        return acc;
      }
      const { parents } = currentNotification.dynamicData;
      parents.forEach((id) => acc.add(id));
      return acc;
    }, new Set<string>());
    return ids;
  }, [notifications]);

  const unreadNotificationItemIds = useMemo(() => {
    const itemIds = notifications.reduce((acc, currentNotification) => {
      if (currentNotification.isRead) {
        return acc;
      }
      acc.add(currentNotification.itemId);
      return acc;
    }, new Set<string>());
    return itemIds;
  }, [notifications]);

  function shouldEntityHaveNotificationDotById(id: string) {
    return (
      unreadNotificationParentIds.has(id) || unreadNotificationItemIds.has(id)
    );
  }

  function hasUnreadNotification(id: string) {
    return unreadNotificationItemIds.has(id);
  }

  function hasUnreadNotificationChildren(id: string) {
    return unreadNotificationParentIds.has(id);
  }

  const totalUnseenCount = data?.totalUnseenCount ?? 0;
  const totalUnreadCount = data?.totalUnreadCount ?? 0;

  const patchNotificationIsRead = useCallback(
    async (id: string, isRead: boolean) => {
      await mutate(
        async () => {
          const response = await centrixFetch({
            method: "post",
            path: `/api/app/notifications/{id}/mark-read-unread`,
            parameters: { path: { id } },
            body: {
              isRead,
              isSeen: true,
            },
          });
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          return {} as any; // data is manually set in the optimisticData function. This is just to satisfy the types.
        },
        {
          optimisticData: (currentData) => {
            if (!currentData || !Array.isArray(currentData.items)) {
              return {
                items: [],
                totalCount: 0,
                totalUnseenCount: 0,
                totalUnreadCount: 0,
              };
            }
            const updatedItems = currentData.items.map((item) => {
              if (item.id !== id) {
                return item;
              }
              return { ...item, isRead };
            });
            const newTotalUnreadCount = isRead
              ? (currentData.totalUnreadCount ?? 0) - 1
              : (currentData.totalUnreadCount ?? 0) + 1;
            const newTotalUnseenCount =
              (currentData.totalUnseenCount ?? 0) > 0
                ? (currentData.totalUnseenCount ?? 0) - 1
                : 0;
            const newNotificationsData: NotificationEndpointResponseJson = {
              ...currentData,
              totalCount: currentData.totalCount ?? 0,
              items: updatedItems as any,
              totalUnreadCount: newTotalUnreadCount,
              totalUnseenCount: newTotalUnseenCount,
            };
            return newNotificationsData as any;
          },
          populateCache: false,
          rollbackOnError: true,
        }
      );
    },
    [mutate, centrixFetch]
  );

  const markNotificationAsRead = usePromiseCallbackTracking(
    (id: string) => patchNotificationIsRead(id, true),
    [patchNotificationIsRead],
    {
      componentName: "useNotifications",
      functionName: "markNotificationAsRead",
      errorMessage: t("markNotificationAsReadError"),
    }
  );

  const markNotificationAsReadByItemId = usePromiseCallbackTracking(
    (itemId: string) => {
      const notification = notifications
        .filter(({ isRead }) => !isRead)
        .find((currentNotification) => currentNotification.itemId === itemId);
      if (!notification) {
        return Promise.resolve();
      }
      return markNotificationAsRead(notification.id);
    },
    [notifications, markNotificationAsRead],
    {
      componentName: "useNotifications",
      functionName: "markNotificationAsReadByItemId",
      errorMessage: t("markNotificationAsReadByItemIdError"),
    }
  );

  const markNotificationAsUnread = usePromiseCallbackTracking(
    (id: string) => patchNotificationIsRead(id, false),
    [patchNotificationIsRead],
    {
      componentName: "useNotifications",
      functionName: "markNotificationAsUnread",
      errorMessage: t("markNotificationAsUnreadError"),
    }
  );

  const markAllNotificationsAsSeen = usePromiseCallbackTracking(
    async () => {
      await mutate(
        async () => {
          const response = await centrixFetch({
            method: "post",
            path: "/api/app/notifications/mark-all-seen",
          });
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          return {} as any; //  data is manually set in the optimisticData function. This is just to satisfy the types.
        },
        {
          optimisticData: (currentData) => {
            if (!currentData || !Array.isArray(currentData?.items)) {
              return {
                items: [],
                totalCount: 0,
                totalUnseenCount: 0,
                totalUnreadCount: 0,
              };
            }
            const updatedItems = currentData.items.map((item) => ({
              ...item,
              isSeen: true,
            }));
            const newData = {
              ...currentData,
              items: updatedItems,
              totalUnseenCount: 0,
            };
            return newData;
          },
          populateCache: false,
          revalidate: false,
          rollbackOnError: true,
        }
      );
    },
    [centrixFetch, mutate],
    {
      componentName: "useNotifications",
      functionName: "markAllNotificationsAsSeen",
      errorMessage: t("markAllNotificationsAsSeenError"),
    }
  );

  const markAllNotificationsAsRead = usePromiseCallbackTracking(
    async () => {
      await mutate(
        async () => {
          const response = await centrixFetch({
            method: "post",
            path: "/api/app/notifications/mark-all-read",
          });
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          return {} as any;
        },
        {
          optimisticData: (currentData) => {
            if (!currentData || !Array.isArray(currentData.items)) {
              return {
                items: [],
                totalCount: 0,
                totalUnseenCount: 0,
                totalUnreadCount: 0,
              };
            }
            const updatedItems = currentData.items.map((item) => ({
              ...item,
              isRead: true,
              isSeen: true,
            }));
            return {
              ...currentData,
              items: updatedItems,
              totalUnseenCount: 0,
              totalUnreadCount: 0,
            };
          },
          populateCache: false,
          rollbackOnError: true,
        }
      );
    },
    [centrixFetch, mutate],
    {
      componentName: "useNotifications",
      functionName: "markAllNotificationsAsRead",
      errorMessage: t("markAllNotificationsAsReadError"),
      successMessage: t("markAllNotificationsAsReadSuccess"),
    }
  );

  const deleteNotification = usePromiseCallbackTracking(
    async (id: string) => {
      await mutate(
        async () => {
          const response = await centrixFetch({
            method: "delete",
            path: `/api/app/notifications/{id}`,
            parameters: { path: { id } },
          });
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          return {} as any; // data is manually set in the optimisticData function. This is just to satisfy the types.
        },
        {
          optimisticData: (currentData) => {
            if (!currentData || !Array.isArray(currentData.items)) {
              return {
                items: [],
                totalCount: 0,
                totalUnseenCount: 0,
                totalUnreadCount: 0,
              };
            }
            let decrementTotalUnreadCount = false;
            let decrementTotalUnseenCount = false;
            const updatedItems = currentData.items.filter((item) => {
              const shouldKeep = item.id !== id;
              if (!shouldKeep) {
                if (!item.isRead) {
                  decrementTotalUnreadCount = true;
                }
                if (!item.isSeen) {
                  decrementTotalUnseenCount = true;
                }
              }
              return shouldKeep;
            });
            return {
              ...currentData,
              items: updatedItems,
              totalUnseenCount: decrementTotalUnseenCount
                ? (currentData.totalUnseenCount ?? 0) - 1
                : currentData.totalUnseenCount,
              totalUnreadCount: decrementTotalUnreadCount
                ? (currentData.totalUnreadCount ?? 0) - 1
                : currentData.totalUnreadCount,
            };
          },
          populateCache: false,
          rollbackOnError: true,
        }
      );
    },
    [centrixFetch, mutate],
    {
      componentName: "useNotifications",
      functionName: "deleteNotification",
      errorMessage: t("deleteNotificationError"),
      successMessage: t("deleteNotificationSuccess"),
    }
  );

  const deleteAllNotifications = usePromiseCallbackTracking(
    async () => {
      await mutate(
        async () => {
          const response = await centrixFetch({
            method: "delete",
            path: `/api/app/notifications`,
          });
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          return {} as any; // data is manually set in the optimisticData function. This is just to satisfy the types.
        },
        {
          optimisticData: (currentData) => {
            if (!currentData) {
              return {
                items: [],
                totalCount: 0,
                totalUnseenCount: 0,
                totalUnreadCount: 0,
              };
            }
            return {
              ...currentData,
              items: [],
              totalUnseenCount: 0,
              totalUnreadCount: 0,
            };
          },
          populateCache: false,
          rollbackOnError: true,
        }
      );
    },
    [centrixFetch, mutate],
    {
      componentName: "useNotifications",
      functionName: "deleteAllNotifications",
      errorMessage: t("deleteAllNotificationsError"),
      successMessage: t("deleteAllNotificationsSuccess"),
    }
  );

  return {
    data,
    isError,
    isLoading,
    mutate: mutate as any,
    notifications,
    totalCount: data?.totalCount ?? 0,
    totalUnreadCount,
    totalUnseenCount,
    markNotificationAsRead,
    markNotificationAsUnread,
    markAllNotificationsAsRead,
    markAllNotificationsAsSeen,
    markNotificationAsReadByItemId,
    deleteNotification,
    deleteAllNotifications,
    shouldEntityHaveNotificationDotById,
    hasUnreadNotification,
    hasUnreadNotificationChildren,
  };
}
