import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";

export function DeleteButton({
  loading = false,
  disabled = false,
  onClick,
  children,
  ...rest
}: LoadingButtonProps) {
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:DeleteButton",
  });
  const defaultButtonText = t("buttonText");
  return (
    <LoadingButton
      variant="contained"
      color="error"
      type="submit"
      loadingPosition="start"
      loading={loading}
      startIcon={<DeleteIcon />}
      disabled={disabled}
      onClick={onClick}
      {...rest}
    >
      {children ?? defaultButtonText}
    </LoadingButton>
  );
}
