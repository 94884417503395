import { FixHubPermissions } from "resources/permissions";
import { useMemo } from "react";
import { useUserPermissionContextsInCurrentBrandRegion } from "./useUserPermissionContextsInCurrentBrandRegion";

export function usePermissionChecker() {
  const { contexts } = useUserPermissionContextsInCurrentBrandRegion();
  const returnValue = useMemo(() => {
    const permissions = contexts?.flatMap(
      (permContext) => permContext.permissions
    );
    return {
      hasAllPermissions: (requiredPermissions: FixHubPermissions[]) => {
        if (requiredPermissions.length === 0) {
          return true;
        }
        return requiredPermissions.every((perm) => permissions?.includes(perm));
      },
      hasSomePermissions: (requiredPermissions: FixHubPermissions[]) => {
        if (requiredPermissions.length === 0) {
          return true;
        }
        return requiredPermissions.some((perm) => permissions?.includes(perm));
      },
    };
  }, [contexts]);

  return returnValue;
}
