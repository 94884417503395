import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMenuItems } from "core/hooks/useMenuItems";
import { NewDocumentFormValues } from "features/DMS/entities";
import {
  useDMSFormErrorHandler,
  useInfiniteDocuments,
} from "features/DMS/hooks";
import { useToaster } from "core/hooks/useToaster";
import { useCentrixFetch } from "core/hooks/useCentrixFetch";
import { useViewableDMSPermissionOptions } from "features/DMS/hooks/useViewableDMSPermissionOptions";
import { DocumentForm, defaultValues } from "./DocumentForm";
import { dmsDocumentFormValuesToCreateUpdateDto } from "../PermissionManagement/ViewablePermissionsManagement/utils/dmsDocumentFormValuesToCreateUpdateDto";

interface DocumentAddProps {
  parentId: string;
  docLibraryId: string;
  cancelOnClick: () => void;
  canManageLibraryContents: boolean;
}

export function DocumentAdd({
  cancelOnClick,
  parentId,
  docLibraryId,
  canManageLibraryContents,
}: DocumentAddProps) {
  const formMethods = useForm<NewDocumentFormValues>({
    defaultValues,
    mode: "onChange",
  });

  const { t } = useTranslation("DocumentManagementSystem", {
    keyPrefix: "Next:DocumentManagementSystem:DocumentAdd",
  });
  const centrixFetch = useCentrixFetch();
  const { mutate: mutateManageableList } = useInfiniteDocuments({
    parentId,
    mode: "manage",
    canManageLibraryContents,
  });
  const { mutate: mutateViewableList } = useInfiniteDocuments({
    parentId,
    mode: "view",
    canManageLibraryContents,
  });
  const { isLoading } = useViewableDMSPermissionOptions(docLibraryId);
  const { mutate: mutateMenuItems } = useMenuItems();
  const { handleSubmit } = formMethods;
  const { errorToast, successToast } = useToaster();
  const { errorHandler } = useDMSFormErrorHandler(formMethods, "DocumentForm");
  const errorMessage = t("POSTError");
  const successMessage = t("POSTSuccess");

  async function onValidSubmit(data: NewDocumentFormValues) {
    const submitData = dmsDocumentFormValuesToCreateUpdateDto(data, parentId);
    const newDocumentResponse = await centrixFetch({
      method: "post",
      path: "/api/app/doc-document",
      body: submitData,
    });
    if (!newDocumentResponse.ok) {
      errorToast(errorMessage);
      return;
    }
    const newDocument = await newDocumentResponse.json();

    await Promise.all([
      mutateManageableList((currentResponses) => {
        const lastResponse = currentResponses?.at(-1);
        if (!currentResponses || !lastResponse) {
          return [{ items: [newDocument], totalCount: 1 }];
        }
        if (Array.isArray(lastResponse?.items)) {
          const oldItems = lastResponse.items;
          const newTotalCount = lastResponse.totalCount + 1;
          const newItems = [...oldItems, newDocument];
          return currentResponses.map((res, index, { length }) =>
            index === length - 1
              ? { items: newItems, totalCount: newTotalCount }
              : res
          );
        }
        return [{ items: [newDocument], totalCount: 1 }];
      }),
      mutateViewableList(),
      mutateMenuItems(),
    ]);
    successToast(successMessage);
    cancelOnClick();
  }

  const onSubmit = handleSubmit(onValidSubmit, errorHandler);

  function clearOnClick() {
    formMethods.reset(defaultValues);
  }

  return (
    <FormProvider {...formMethods}>
      <DocumentForm
        isLoading={isLoading}
        componentName="DocumentAdd"
        formMethods={formMethods}
        onSubmit={onSubmit}
        clearOnClick={clearOnClick}
        cancelOnClick={cancelOnClick}
        docLibraryId={docLibraryId}
      />
    </FormProvider>
  );
}
