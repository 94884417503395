import SubDomainMap from "resources/SubDomainMap";
import DomainMap from "resources/DomainMap";
import { BrandCode } from "core/entities";

export function getBrandCodeFromHost(host: string): BrandCode | undefined {
  // has subdomain
  if (host.includes(".")) {
    const parts = host.split(".");
    const [subdomain, domainWithPossiblePort] = parts;
    const domainParts = domainWithPossiblePort?.split(":");
    const domain = domainParts?.[0];
    const domainBrandCode = domain && DomainMap.get(domain);
    if (domainBrandCode) {
      return domainBrandCode;
    }
    const subdomainBrandCode = subdomain && SubDomainMap.get(subdomain);
    if (subdomainBrandCode) {
      return subdomainBrandCode;
    }
    return undefined;
  }
  // no subdomain
  // remove port if exists
  const parts = host.split(":");
  const domain = parts[0];
  const domainBrandCode = domain && DomainMap.get(domain);
  return domainBrandCode || undefined;
}
