import { chunkify } from "core/utils/chunkify";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useIntlFormatterLocales } from "./useIntlFormatterLocales";
import { useToaster } from "./useToaster";

export function useValidationErrorToaster() {
  const { errorToast } = useToaster();
  const locales = useIntlFormatterLocales();
  const { t } = useTranslation();
  return useCallback(
    (errorFields: string[]) => {
      const fieldsList = new Intl.ListFormat(locales, {
        style: "long",
        type: "conjunction",
      }).formatToParts(errorFields);

      const chunkedListParts = chunkify(fieldsList, 6);
      chunkedListParts.forEach((parts, index) => {
        const partsString = parts.map(({ value }) => value).join("");
        const errorToastMessage =
          index === 0
            ? t("Next:Core:ValidationError", {
                fields: partsString,
                count: fieldsList.length,
              })
            : `... ${partsString}`;

        errorToast(errorToastMessage, "bottom-center");
      });
    },
    [errorToast, locales, t]
  );
}
