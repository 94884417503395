import { KeyContext } from "core/context/KeyContext";
import { useCallback, useContext } from "react";
import { useSWRConfig } from "swr";

/**
 * This hook completely clears the SWR cache and forces a re-mount at the root of the application
 * by incrementing the key in the KeyContext.
 * This is useful for when you need to clear the cache for all SWR hooks in the application, for example when changing regions.
 * @docs https://swr.vercel.app/docs/advanced/cache#modify-the-cache-data
 * @returns A function that clears the cache for the current region.
 */
export function useClearSWRCache() {
  const { cache } = useSWRConfig();
  const keyState = useContext(KeyContext);
  const setKey = keyState[1];
  return useCallback(() => {
    (cache as Map<string, any>).clear();
    // Increment the key to force a re-render of all SWR hooks.
    setKey((prev) => prev + 1);
  }, [cache, setKey]);
}
