import { ALL_VALUE } from "appConfiguration";

export function filterRegionOptionsBySelectedBrands(
  selectedBrands: string[],
  regionOptions: { label: string; value: string }[],
  regionsByBrand: Record<string, string[]>
) {
  if (selectedBrands.includes(ALL_VALUE) || selectedBrands.length === 0) {
    return regionOptions;
  }
  return regionOptions.filter(({ value: regionValue }) => {
    if (regionValue === ALL_VALUE) {
      return true;
    }
    return selectedBrands.some((brand) =>
      regionsByBrand[brand]?.includes(regionValue)
    );
  });
}
