import { DependencyList, useEffect } from "react";
import { useTimeout } from "./useTimeout";

/**
 * Custom hook that debounces a callback function.
 * The callback function will be invoked after a specified delay, only if there are no subsequent calls within that delay.
 *
 * @param callback - The callback function to be debounced.
 * @param dependencies - An array of dependencies that will trigger the callback function when changed.
 * @param delayInMilliseconds - The delay in milliseconds before invoking the callback function.
 */
export function useDebounce(
  callback: (...args: any[]) => void,
  dependencies: DependencyList,
  delayInMilliseconds: number
) {
  const { reset, clear } = useTimeout(callback, delayInMilliseconds);
  useEffect(reset, [...dependencies, reset]);
  // eslint-disable-next-line react-compiler/react-compiler
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(clear, []);
}
