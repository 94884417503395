import { useRef, useLayoutEffect, useState } from "react";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";

interface SwitcherProps {
  ComponentOne: JSX.Element;
  ComponentTwo: JSX.Element;
  switchPosition: boolean;
}

/**
 * Used to switch between visible components
 * ```
 * <ComponentSwitcher
 *    switchPosition={true} // will display ComponentOne
 *    ComponentOne={<Typography>First Component</Typography>}
 *    ComponentTwo={<Typography>Second Component</Typography>}
 * />
 * ```
 */
export function ComponentSwitcher({
  ComponentOne,
  ComponentTwo,
  switchPosition = false,
}: SwitcherProps) {
  const containerRef = useRef<HTMLElement>(null);
  const [containerEl, setContainerEl] = useState<HTMLElement | null>(null);

  useLayoutEffect(() => {
    setContainerEl(containerRef.current);
  }, []);

  return (
    <Box
      ref={containerRef}
      sx={{ overflowX: "clip", display: "grid", gridTemplateAreas: "'stack'" }}
    >
      <Slide
        appear={false}
        in={switchPosition}
        direction="right"
        style={{ gridArea: "stack" }}
        container={containerEl}
      >
        <Box>{ComponentOne}</Box>
      </Slide>
      <Slide
        appear={false}
        in={!switchPosition}
        direction="left"
        style={{ gridArea: "stack" }}
        container={containerEl}
      >
        <Box>{ComponentTwo}</Box>
      </Slide>
    </Box>
  );
}
