import React, { useState } from "react";
import { AppProps } from "next/app";
import { GlobalLoader } from "core/components/GlobalLoader";
import { Layout } from "core/components/Layout";
import Authorize from "core/components/Authorize";
import { ExtensoTokenChecker } from "core/components/ExtensoTokenChecker";
import { PagePermissionChecker } from "core/components/PagePermissionChecker";
import { PageErrorBoundary } from "core/components/PageErrorBoundary";
import { LayoutBypass } from "core/components/LayoutBypass";
import ExtensoTokenProvider from "core/context/ExtensoContext";
import { REVALIDATE_IF_STALE } from "appConfiguration";
import { SWRConfig } from "swr";
import type { BrandCode, RegionCode } from "core/entities";
import { RouterLocale } from "core/components/RouterLocale";
import { useRouter } from "next/router";
import { ThemeOptions } from "@mui/material";
import { CustomThemePicker } from "core/pages/LandingPage/CustomThemePicker";
import { KeyContext } from "core/context/KeyContext";
import { ContextVerifier } from "core/components/GlobalLoader/ContextVerifier";
import { SharedAppWrapper } from "core/components/SharedAppWrapper";

/**
 * PitstopApp is an internal Hub application component that serves as the main
 * entry point for the Pitstop application. It handles the overall structure,
 * authentication, and routing for the application.
 */
export function PitstopApp({
  Component,
  pageProps,
  brandCode,
  availableRegions,
}: { brandCode: BrandCode; availableRegions: RegionCode[] } & Pick<
  AppProps,
  "Component" | "pageProps"
>) {
  const forceRemountKeyState = useState(0);
  const [forceRemountKey] = forceRemountKeyState;
  const {
    query: { customizeTheme },
  } = useRouter();

  const [customTheme, setCustomTheme] = useState<ThemeOptions | null>(null);

  return (
    <KeyContext.Provider value={forceRemountKeyState}>
      <SWRConfig
        key={forceRemountKey}
        value={{
          revalidateOnFocus: false,
          revalidateIfStale: REVALIDATE_IF_STALE,
          errorRetryCount: 5,
        }}
      >
        <ExtensoTokenProvider>
          {customizeTheme === "true" && (
            <CustomThemePicker setCustomTheme={setCustomTheme} />
          )}
          <SharedAppWrapper
            brandCode={brandCode}
            customTheme={customTheme}
            availableRegions={availableRegions}
          >
            <Authorize>
              <RouterLocale />
              <LayoutBypass Component={Component} pageProps={pageProps}>
                <ContextVerifier>
                  <GlobalLoader>
                    <ExtensoTokenChecker>
                      <Layout>
                        <PagePermissionChecker>
                          <PageErrorBoundary>
                            <Component {...pageProps} />
                          </PageErrorBoundary>
                        </PagePermissionChecker>
                      </Layout>
                    </ExtensoTokenChecker>
                  </GlobalLoader>
                </ContextVerifier>
              </LayoutBypass>
            </Authorize>
          </SharedAppWrapper>
        </ExtensoTokenProvider>
      </SWRConfig>
    </KeyContext.Provider>
  );
}
