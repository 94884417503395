import Button, { ButtonProps } from "@mui/material/Button";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useTranslation } from "react-i18next";

export function ResetButton({ children, ...props }: ButtonProps) {
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:ResetButton",
  });
  return (
    <Button
      variant="outlined"
      color="primary"
      startIcon={<RestartAltIcon />}
      {...props}
    >
      {children ?? t("buttonText")}
    </Button>
  );
}
