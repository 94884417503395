import { ReactNode } from "react";
import { ApplicationErrorBoundaryPage } from "core/pages/ApplicationErrorBoundaryPage";
import { useMenuItems } from "core/hooks/useMenuItems";
import { useToken } from "core/hooks/useToken";
import { ErrorComponent } from "../ErrorComponent";
import { ApplicationLoadingDisplay } from "../ApplicationLoadingDisplay";

interface GlobalLoaderProps {
  children: ReactNode;
}

export function MainLoader({ children }: GlobalLoaderProps) {
  const { isLoading, isError } = useMenuItems();

  if (isError) {
    return (
      <ApplicationErrorBoundaryPage>
        <ErrorComponent apiError={isError} />
      </ApplicationErrorBoundaryPage>
    );
  }
  if (isLoading) {
    return <ApplicationLoadingDisplay />;
  }

  return <>{children}</>;
}

function TokenLoader({ children }: { children: ReactNode }) {
  const token = useToken();
  if (!token) {
    return <ApplicationLoadingDisplay />;
  }
  return <>{children}</>;
}

export function GlobalLoader({ children }: { children: ReactNode }) {
  return (
    <TokenLoader>
      <MainLoader>{children}</MainLoader>
    </TokenLoader>
  );
}
