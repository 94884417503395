export function objectToFormData(obj: Object) {
  const formData = new FormData();
  Object.entries(obj).forEach(([key, value]) => {
    // stringify normal objects/arrays (not Date or File)
    if (value instanceof Date) {
      formData.append(key, value.toISOString());
      return;
    }
    if (!(value instanceof File) && typeof value === "object") {
      formData.append(key, JSON.stringify(value));
      return;
    }
    formData.append(key, value);
  });
  return formData;
}
