import { RegionCode } from "core/entities";
import { useRouter } from "next/router";
import { isValidRegion } from "core/utils/isValidRegion";
import { createContext, useContext } from "react";
import { AvailableRoutes } from "resources/availableRoutes";
import { getBrand } from "../utils/getBrand";
import { useAvailableRegions } from "./useAvailableRegions";

export const REGION_KEY = "region";

export const OverrideRegionContext = createContext<RegionCode | null>(null);

export function useRegion(): RegionCode {
  const overrideRegion = useContext(OverrideRegionContext);
  const {
    query: { region: queryRegion, ...query },
    replace,
    pathname,
  } = useRouter();
  const availableRegions = useAvailableRegions();
  if (overrideRegion) {
    return overrideRegion;
  }
  const { brandCode } = getBrand();
  if (!brandCode) {
    throw new Error("useRegion hook requires a brand code");
  }
  const isQueryValid = isValidRegion(queryRegion, availableRegions);
  if (isQueryValid) {
    return queryRegion;
  }

  const SignInPath: AvailableRoutes = "/signin-oidc";
  const isSignInPath = pathname === SignInPath;
  const shouldReplaceRegion = !isSignInPath && pathname.includes("region");

  const storageKey = `user-storage.${brandCode}-${REGION_KEY}`;
  const storageRegion = JSON.parse(localStorage.getItem(storageKey) ?? '""');
  const isStorageRegionValid = isValidRegion(storageRegion, availableRegions);
  if (isStorageRegionValid) {
    if (shouldReplaceRegion) {
      replace({
        pathname,
        query: { ...query, region: storageRegion },
      });
    }
    return storageRegion;
  }

  if (availableRegions.includes("ca")) {
    if (shouldReplaceRegion) {
      replace({
        pathname,
        query: { ...query, region: "ca" },
      });
    }
    return "ca";
  }
  const fallbackRegion = availableRegions[0];
  if (fallbackRegion) {
    if (shouldReplaceRegion) {
      replace({
        pathname,
        query: { ...query, region: fallbackRegion },
      });
    }
    return fallbackRegion;
  }

  throw new Error(
    `no "availableRegions" configured for the brand ${brandCode}`
  );
}
