import { useCallback } from "react";
import { MAX_MAX_RESULT_COUNT } from "appConfiguration";
import { useJsonFetcherWithHeaders } from "./useJsonFetcherWithHeaders";

/**
 * To be used with the `useCentrixApi` hook. You probably don't need to use this directly.
 *
 * Custom hook that fetches all items from a paginated API endpoint.
 *
 * This hook uses a JSON fetcher with headers to make requests to the given URL.
 * It first fetches the initial data and checks if the total count of items is
 * greater than the number of items fetched. If so, it continues to fetch the
 * remaining items in batches until all items are retrieved.
 *
 * @throws {Error} Throws an error if the response is invalid or does not match the expected format.
 */
export function useFetchAllPaginatedData() {
  const fetcher = useJsonFetcherWithHeaders();
  return useCallback(
    async (url: string | URL | Request) => {
      const baseURL = new URL(url.toString());
      baseURL.searchParams.delete("fetchAll");
      baseURL.searchParams.set(
        "MaxResultCount",
        MAX_MAX_RESULT_COUNT.toString()
      );
      baseURL.searchParams.set("SkipCount", "0");
      const initialData = (await fetcher(baseURL.toString())) as {
        items: any[];
        totalCount: number;
      };
      if (
        !Array.isArray(initialData?.items) ||
        typeof initialData.totalCount !== "number"
      ) {
        throw new Error("Invalid response. Expected PagedAndSortedResultDto");
      }

      if (initialData.items?.length >= initialData.totalCount) {
        return initialData as any;
      }

      const skipCounts = [
        ...Array(Math.ceil(initialData.totalCount / MAX_MAX_RESULT_COUNT)),
      ]
        .map((_, index) => index * MAX_MAX_RESULT_COUNT)
        .slice(1);
      const responseDatePromises = skipCounts.map((skipCount) => {
        const newURL = new URL(baseURL.toString());
        newURL.searchParams.set("SkipCount", skipCount.toString());
        return fetcher(newURL.toString());
      });
      const responseDatas = (await Promise.all(responseDatePromises)) as {
        totalCount: number;
        items: any[];
      }[];

      const items = [
        ...initialData.items,
        ...responseDatas.flatMap((r) => r.items),
      ];

      return { items, totalCount: initialData.totalCount } as any;
    },
    [fetcher]
  );
}
