import Box from "@mui/material/Box";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "core/hooks/useNavigate";
import { ReactHookTextField } from "../ReactHookFormComponents/ReactHookTextField";

const defaultValues = { filterText: "" };

export function MobileNavSearch() {
  const { events } = useRouter();
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:MobileNavSearch",
  });
  const navigate = useNavigate();
  const { control, handleSubmit, reset } = useForm({
    defaultValues,
  });

  // clears search bar on nav except when naving to search page
  useEffect(() => {
    const eventArgs: Parameters<typeof events.on> = [
      "routeChangeStart",
      (url: string) => {
        if (!/search/.test(url)) {
          reset(defaultValues);
        }
      },
    ];
    events.on(...eventArgs);
    return () => {
      events.off(...eventArgs);
    };
  }, [events, reset]);

  return (
    <Box
      minWidth={300}
      px={1}
      component="form"
      onSubmit={handleSubmit(async ({ filterText }) => {
        await navigate(`/search?filterText=${filterText}`);
      })}
    >
      <ReactHookTextField
        fullWidth
        textFieldProps={{ variant: "standard" }}
        control={control}
        name="filterText"
        label={t("filterText")}
      />
    </Box>
  );
}
