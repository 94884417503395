import { useRef, useState, useLayoutEffect } from "react";

export function useElement<T>() {
  const ref = useRef<T>(null);
  const [element, setElement] = useState<T | null>(null);
  useLayoutEffect(() => {
    setElement(ref.current);
  }, []);
  return { ref, element, setElement };
}
