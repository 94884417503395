import { useCentrixApi } from "core/hooks/useCentrixApi";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export function useFavorites() {
  const { locale } = useRouter();

  const { data, isError, isLoading, mutate } = useCentrixApi({
    path: "/api/app/favorites/current-user-favorites",
    parameters: { query: { Locale: locale } },
  });

  const { t: tPage } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:PageTitle",
  });
  const { t: tExtenso } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:ExtensoReportPage.title",
  });
  return useMemo(() => {
    const processedItems =
      data?.items?.map((item) => {
        if (item.itemType === "Extenso") {
          const code = item.itemId?.split("/").at(1);
          if (!code) {
            return item;
          }
          const title = tExtenso(code);
          return { ...item, title };
        }
        if (item.itemType === "Page" && item.itemId) {
          return { ...item, title: tPage(item.itemId) };
        }
        if (!item.title) {
          return { ...item, title: `${item.itemType}:${item.itemId}` };
        }
        return item;
      }) ?? [];

    return {
      data: data ? { ...data, items: processedItems } : undefined,
      isError,
      isLoading,
      mutate,
    };
  }, [data, isError, isLoading, mutate, tExtenso, tPage]);
}
