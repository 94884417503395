import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { useToaster } from "core/hooks/useToaster";
import { useCentrixFetch } from "core/hooks/useCentrixFetch";
import { CancelButton } from "core/components/CancelButton";
import { DeleteButton } from "core/components/DeleteButton";
import { useCategories } from "../hooks";

interface SubCategoryDeleteProps {
  id: string;
  parentId: string;
  cancelOnClick: () => void;
  canManageLibraryContents: boolean;
}

export function SubCategoryDelete({
  id,
  parentId,
  cancelOnClick,
  canManageLibraryContents,
}: SubCategoryDeleteProps) {
  const { t } = useTranslation("DocumentManagementSystem", {
    keyPrefix: "Next:DocumentManagementSystem:SubCategoryDelete",
  });
  const { mutate } = useCategories({
    parentId,
    mode: "manage",
    canManageLibraryContents,
  });
  const { successToast, errorToast } = useToaster();
  const centrixFetch = useCentrixFetch();
  const successMessage = t("successToastMessage");
  const errorMessage = t("errorToastMessage");

  async function onDeleteClick() {
    const deleteRes = await centrixFetch({
      method: "delete",
      path: "/api/app/doc-category/{id}",
      parameters: { path: { id } },
    });
    if (!deleteRes.ok) {
      errorToast(errorMessage);
      return;
    }
    successToast(successMessage);

    mutate((data) => {
      if (!data || !Array.isArray(data.items)) {
        return { items: [], totalCount: 0 };
      }
      const newItems = data.items.filter((category) => category.id !== id);
      return { items: newItems, totalCount: newItems.length };
    });
    cancelOnClick();
  }
  return (
    <Box>
      <Typography
        variant="h5"
        mx="auto"
        mb={4}
        textAlign="center"
        maxWidth="65ch"
      >
        {t("warningMessage")}
      </Typography>
      <Typography
        variant="h5"
        mx="auto"
        mb={4}
        textAlign="center"
        maxWidth="65ch"
      >
        {t("confirmMessage")}
      </Typography>
      <Stack spacing={2} direction="row" justifyContent="center">
        <CancelButton onClick={cancelOnClick}>{t("cancelButton")}</CancelButton>
        <DeleteButton onClick={onDeleteClick}>{t("deleteButton")}</DeleteButton>
      </Stack>
    </Box>
  );
}
