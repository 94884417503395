import LocationOnIcon from "@mui/icons-material/LocationOn";
import OpenInNew from "@mui/icons-material/OpenInNew";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CustomLink } from "core/components/CustomLink";
import { useFormatDate } from "core/hooks/useFormatDate";
import { IEventArticleSummaryDto } from "features/NewsEvents/entities";
import { useTranslation } from "react-i18next";

type EventWidgetTileProps = Pick<
  IEventArticleSummaryDto,
  "url" | "title" | "address" | "startDate" | "id"
>;

function DateBox({ day, month }: { day: number; month: string }) {
  return (
    <Stack
      paddingY={2}
      paddingX={3}
      borderRadius="5px"
      bgcolor="primary.main"
      color="primary.contrastText"
      textAlign="center"
      alignItems="center"
      justifyContent="center"
      gap={1}
    >
      <Typography fontSize="16px" lineHeight={1}>
        {month}
      </Typography>
      <Typography fontSize="46px" lineHeight={1}>
        {day}
      </Typography>
    </Stack>
  );
}

export function EventsWidgetTile({
  id,
  title,
  startDate,
  address,
  url,
}: EventWidgetTileProps) {
  const { t } = useTranslation("NewsEvents", {
    keyPrefix: "Next:NewsEvents:EventsWidgetTile",
  });
  const getMonth = useFormatDate({ month: "long" });
  if (!startDate) {
    return null;
  }
  const month = getMonth(startDate);
  const day = new Date(startDate).getDate();
  const href = `/events/${id}`;
  return (
    <Box
      display="flex"
      flexDirection="row"
      gap={2}
      alignItems="flex-start"
      justifyContent="center"
      flexWrap="wrap"
    >
      <CustomLink href={href}>
        <DateBox day={day} month={month} />
      </CustomLink>
      <Stack flex={1} minWidth="125px" alignItems="center">
        <CustomLink href={href}>
          <Typography variant="body1">{title}</Typography>
        </CustomLink>
        {address && (
          <CustomLink
            textAlign="center"
            target="_blank"
            href={`https://www.google.com/maps/search/${encodeURIComponent(
              address
            )}`}
          >
            <Typography color="text.secondary">
              <LocationOnIcon
                sx={{ marginBottom: "-3px" }} // generally don't use negative margins, but it seems like the best option to center an icon inline
                color="primary"
              />{" "}
              {address}
            </Typography>
          </CustomLink>
        )}
        {url && (
          <CustomLink
            href={url}
            target="_blank"
            maxWidth="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            color="text.secondary"
          >
            <OpenInNew color="primary" sx={{ marginBottom: "-7px" }} /> {url}
          </CustomLink>
        )}
        <CustomLink href={href} sx={{ mt: 2 }}>
          {t("details")}
        </CustomLink>
      </Stack>
    </Box>
  );
}
