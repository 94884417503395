import { useCallback } from "react";
import { useTranslation } from "react-i18next";

/**
 * Provides a formatter for region names.
 * @example
 * ```tsx
 * const formatRegionName = useRegionNameFormatter();
 * const regionName = formatRegionName("us");
 * console.log(regionName); // "United States"
 */
export function useRegionNameFormatter() {
  const keyPrefix = "Region:RegionName";
  const { t, i18n } = useTranslation("Fixhub", {
    keyPrefix,
  });
  return useCallback(
    (twoLetterIsoCode: string) => {
      if (!i18n.exists(`${keyPrefix}.${twoLetterIsoCode}`)) {
        return null;
      }
      return t(twoLetterIsoCode);
    },
    [i18n, t]
  );
}
