import { useMemo } from "react";
import { i18nLocales, SupportedLocales } from "appConfiguration";
import { useCentrixApi } from "./useCentrixApi";

/**
 * A hook that returns an array of available locales.
 * Intersects the data returned from "/api/app/available-languages" API endpoint
 * and the i18nLocales configured in next.config.js / config.ts
 * @returns An array of available locales.
 */
export function useAvailableLocales() {
  const { data, isError, isLoading } = useCentrixApi({
    path: "/api/app/available-languages",
    swrOptions: {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  });
  const availableLocales = useMemo(() => {
    if (!data) {
      return [];
    }
    return data
      .map((language) => language.cultureName)
      .filter((locale): locale is SupportedLocales =>
        Boolean(locale && i18nLocales.includes(locale as SupportedLocales))
      );
  }, [data]);
  return { data, isError, isLoading, availableLocales };
}
