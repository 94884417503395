import { useAuth } from "oidc-react";
import { usePermission } from "core/hooks/usePermission";
import { paths } from "resources/api-schema";
import { useCentrixApi } from "./useCentrixApi";

export type UserInfo =
  paths["/api/identity/users/{id}"]["get"]["responses"][200]["content"]["application/json"];

export type UpdateUserInfo = NonNullable<
  paths["/api/identity/users/{id}"]["put"]["requestBody"]
>["content"]["application/json"];

export function useUserInfo() {
  const { userData } = useAuth();
  // TODO use a custom endpoint that exposes current user's info
  const canGetInfo = usePermission("AbpIdentity.Users");
  // only fetch once
  return useCentrixApi({
    path: "/api/identity/users/{id}",
    parameters: { path: { id: userData?.profile?.sub as string } },
    shouldFetch: canGetInfo && typeof userData?.profile?.sub === "string",
    swrOptions: {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  });
}
