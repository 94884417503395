import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import { useRouter } from "next/router";
import Skeleton from "@mui/material/Skeleton";
import { getBrand } from "core/utils/getBrand";
import { useRegion } from "core/hooks/useRegion";
import { useCentrixApi } from "core/hooks/useCentrixApi";
import { GridAreaContainer } from "../GridAreaContainer";
import { Carrousel } from "../Carrousel";
import { ErrorComponent } from "../ErrorComponent";

export function AdvertisementWidget() {
  const { locale } = useRouter();
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:AdvertisementWidget",
  });
  const { brandCode } = getBrand();
  const region = useRegion();
  const { data, isError, isLoading } = useCentrixApi({
    path: "/api/app/advertisement-banner/public-list",
    parameters: {
      query: {
        Locale: locale!,
        BrandShortCode: brandCode,
        IsoAlpha2Code: region,
      },
    },
    fetchAll: true,
  });
  if (isLoading) {
    return (
      <GridAreaContainer gridArea="ads" title={t("gridAreaContainer")}>
        <Skeleton variant="rectangular" height={400} />
      </GridAreaContainer>
    );
  }
  if (isError) {
    return (
      <GridAreaContainer gridArea="ads" title={t("gridAreaContainer")}>
        <Paper sx={{ p: 2 }}>
          <ErrorComponent apiError={isError} />
        </Paper>
      </GridAreaContainer>
    );
  }
  const images =
    data?.items?.filter(
      (entry): entry is Exclude<typeof entry, null | undefined> =>
        Boolean(entry?.id && entry?.imageSrc && entry?.altText && entry?.title)
    ) ?? [];

  if (images.length === 0) {
    return null;
  }

  return (
    <GridAreaContainer
      gridArea="ads"
      title={t("gridAreaContainer")}
      disableBottomSlot
    >
      <Carrousel
        images={images}
        intervalInMilliseconds={6000}
        paperProps={{
          sx: {
            aspectRatio: "3 / 1",
            height: "auto",
            maxHeight: "calc(100vh - 250px)",
          },
        }}
      />
    </GridAreaContainer>
  );
}
