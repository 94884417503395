import { useTranslation } from "react-i18next";
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";

export function SubmitButton({
  loading = false,
  disabled = false,
  onClick,
  children,
  ...rest
}: LoadingButtonProps) {
  const { t } = useTranslation();
  return (
    <LoadingButton
      variant="contained"
      color="primary"
      type="submit"
      loadingPosition="start"
      loading={loading}
      startIcon={<SendIcon />}
      disabled={disabled}
      onClick={onClick}
      {...rest}
    >
      {children ?? t("Next:Core:Submit")}
    </LoadingButton>
  );
}
