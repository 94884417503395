import { ExtractParameters } from "core/entities/APITypes";
import { useCentrixApi, UseCentrixApiArg } from "./useCentrixApi";

export type PublicShopsFilter = ExtractParameters<
  "/api/shared-entities/shops/public",
  "get"
>["query"];

export function usePublicShops({
  filter,
  shouldFetch = true,
  fetchAll = false,
  swrOptions,
}: {
  filter?: PublicShopsFilter;
  shouldFetch?: boolean;
  fetchAll?: boolean;
  swrOptions?: UseCentrixApiArg<"/api/shared-entities/shops/public">["swrOptions"];
}) {
  return useCentrixApi({
    path: "/api/shared-entities/shops/public",
    parameters: { query: filter },
    shouldFetch,
    fetchAll,
    swrOptions,
  });
}
