import { copyTranslationsToClipboard } from "i18n.config";
import React from "react";
import { isDevelopment } from "appConfiguration";
import type { NextComponentType, NextPageContext } from "next";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useRouter } from "next/router";
import { AvailableRoutes } from "resources/availableRoutes";
import { PublicLanguageSelect } from "./PublicLanguageSelect";

/**
 * PublicApp is an internal Hub application component that serves as the main
 * entry point for the Public application. Currently used for the public Estimate Request page, and any future public pages.
 */
export function PublicApp({
  Component,
  pageProps,
}: {
  Component: NextComponentType<NextPageContext, any, any>;
  pageProps: any;
}) {
  const { pathname } = useRouter();

  const errorRoute: AvailableRoutes = "/public/error-code";
  if (pathname === errorRoute) {
    return <Component {...pageProps} />;
  }
  return (
    <>
      <Stack p={1} direction="row" gap={2} justifyContent="flex-end">
        {isDevelopment && (
          <Button
            variant="contained"
            color="primary"
            onClick={copyTranslationsToClipboard}
          >
            Get Translations
          </Button>
        )}
        <PublicLanguageSelect />
      </Stack>
      <Component {...pageProps} />
    </>
  );
}
