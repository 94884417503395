import "mock-service-worker/initMSW";
import { reactPlugin } from "appInsightsWeb";
import React, { useEffect, useState } from "react";
import App, { AppContext, AppInitialProps, AppProps } from "next/app";
import { LicenseInfo } from "@mui/x-license";
import { MUI_X_LICENSE, validateConfigServerSide } from "appConfiguration";
import type { BrandCode, RegionCode } from "core/entities";
import { ApplicationLoadingDisplay } from "core/components/ApplicationLoadingDisplay";
import { ApplicationConfigurationErrorPage } from "core/pages/ApplicationConfigurationErrorPage";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useRouter } from "next/router";
import { SharedAppWrapper } from "core/components/SharedAppWrapper";
import { PitstopApp } from "core/components/PitstopApp";
import { PublicApp } from "core/components/PublicApp";

// Assert invariants
validateConfigServerSide();

// https://mui.com/components/data-grid/getting-started/#license-key-installation
LicenseInfo.setLicenseKey(MUI_X_LICENSE);

type ApplicationMainProps = {
  config:
    | {
        brandCode: BrandCode;
        availableRegions: RegionCode[];
      }
    | { error: { message: string } };
};

export default function ApplicationMain({
  Component,
  pageProps,
  config,
}: AppProps & ApplicationMainProps) {
  const { pathname } = useRouter();

  const [initialConfig] = useState(config);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    setIsInitialLoad(false);
  }, []);

  const currentBrandCode =
    "brandCode" in initialConfig ? initialConfig.brandCode : null;

  if (isInitialLoad) {
    return (
      <SharedAppWrapper brandCode={currentBrandCode}>
        <ApplicationLoadingDisplay />
      </SharedAppWrapper>
    );
  }

  if ("error" in initialConfig) {
    reactPlugin.trackException({
      severityLevel: SeverityLevel.Error,
      properties: { component: "_app", config: initialConfig },
    });
    return (
      <SharedAppWrapper brandCode={currentBrandCode}>
        <ApplicationConfigurationErrorPage
          message={initialConfig.error.message}
        />
      </SharedAppWrapper>
    );
  }

  const { availableRegions, brandCode } = initialConfig;

  const isPublic = pathname.split("/").at(1) === "public";
  if (isPublic) {
    return (
      <SharedAppWrapper
        brandCode={currentBrandCode}
        availableRegions={availableRegions}
      >
        <PublicApp Component={Component} pageProps={pageProps} />
      </SharedAppWrapper>
    );
  }

  return (
    <PitstopApp
      brandCode={brandCode}
      availableRegions={availableRegions}
      Component={Component}
      pageProps={pageProps}
    />
  );
}

ApplicationMain.getInitialProps = async (
  context: AppContext
): Promise<AppInitialProps & ApplicationMainProps> => {
  // https://nextjs.org/docs/advanced-features/custom-app
  const appProps = await App.getInitialProps(context);
  const host = context.ctx.req?.headers?.host;
  if (host) {
    const { getPitstopConfig } = await import("core/utils/getPitstopConfig");
    const config = await getPitstopConfig(host);
    return { ...appProps, config };
  }
  return {
    ...appProps,
    config: { error: { message: "client side getInitialProps call" } },
  };
};
