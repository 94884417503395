import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useRouter } from "next/router";
import MenuIcon from "@mui/icons-material/Menu";
import { MyLinksMenu } from "features/MyLinks/components/MyLinksMenu";
import { useTranslation } from "react-i18next";
import { HeaderNotifications } from "features/Notifications/HeaderNotifications";
import { CommunityFeedback } from "features/Notifications/CommunityFeedback";
import { useCanManage } from "core/hooks/useCanManage";
import { useBreakpoints } from "core/hooks/useBreakpoints";
import SearchBar from "./SearchBar";
import { CustomLink } from "./CustomLink";
import { MenuOption, UserMenu } from "./UserMenu";
import { MobileNav } from "./MobileNav";
import { BrandLogo } from "./BrandLogo";
import { DesktopGlobalHeaderNav } from "./Nav";
import { GlobalAlertsBar, useGlobalAlertsBar } from "./GlobalAlertsBar";

const menuOptions: MenuOption[] = ["user-preferences", "logout"];

export function GlobalHeader() {
  const [isMobileNavMenuOpen, setIsMobileNavMenuOpen] = useState(false);
  const { events } = useRouter();
  const canManage = useCanManage();
  const { isDesktop } = useBreakpoints();
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:GlobalHeader",
  });
  function closeMenu() {
    setIsMobileNavMenuOpen(false);
  }
  const { alerts, onAlertClose } = useGlobalAlertsBar();
  useEffect(() => {
    const eventArgs: Parameters<typeof events.on> = [
      "routeChangeStart",
      closeMenu,
    ];
    events.on(...eventArgs);
    return () => {
      events.off(...eventArgs);
    };
  }, [events]);

  if (isDesktop) {
    return (
      <>
        <Box
          component="header"
          sx={{
            top: 0,
            position: "sticky",
            boxShadow: (theme) => theme.shadows[1],
            zIndex: 500,
          }}
        >
          <AppBar position="static" component="section">
            <Toolbar
              disableGutters
              sx={{ px: 3, justifyContent: "space-between", gap: 3 }}
            >
              <SearchBar />
              {canManage && (
                <Button
                  LinkComponent={CustomLink}
                  href="/manage"
                  sx={{ color: "primary.contrastText" }}
                >
                  {t("manage")}
                </Button>
              )}

              <MyLinksMenu />
              <HeaderNotifications />
              <CommunityFeedback />
              <UserMenu menuItems={menuOptions} />
            </Toolbar>
          </AppBar>
          <DesktopGlobalHeaderNav />
        </Box>
        <GlobalAlertsBar alerts={alerts} onClose={onAlertClose} />
      </>
    );
  }
  // Mobile header
  return (
    <>
      <Box
        data-testid="mobile-header"
        component="header"
        sx={{
          top: 0,
          position: "sticky",
          boxShadow: (theme) => theme.shadows[1],
          zIndex: 500,
        }}
      >
        <AppBar position="static" component="section">
          <Toolbar
            disableGutters
            sx={{
              display: "grid",
              justifyItems: "center",
              gridTemplateColumns: "105px 1fr 105px",
              gap: 3,
            }}
          >
            <Stack direction="row">
              <IconButton
                data-testid="open-mobile-menu-button"
                onClick={() => {
                  setIsMobileNavMenuOpen(true);
                }}
              >
                <MenuIcon sx={{ color: "primary.contrastText" }} />
              </IconButton>
            </Stack>
            <Box pt={1} maxWidth={175}>
              <CustomLink href="/">
                <BrandLogo />
              </CustomLink>
            </Box>
            <Stack direction="row" justifyContent="end">
              <HeaderNotifications />
              <UserMenu menuItems={menuOptions} />
            </Stack>
          </Toolbar>
        </AppBar>
      </Box>
      <MobileNav open={isMobileNavMenuOpen} handleClose={closeMenu} />
      <GlobalAlertsBar alerts={alerts} onClose={onAlertClose} />
    </>
  );
}
