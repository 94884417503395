import Alert, { AlertProps } from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Container from "@mui/material/Container";
import { useCentrixApi } from "core/hooks/useCentrixApi";
import { useCallback, useMemo, useState } from "react";
import { components } from "resources/api-schema";

type AlertMessage =
  components["schemas"]["Fixnetwork.SharedEntities.BannerAlerts.BannerAlertDto"];
type AlertType =
  components["schemas"]["Fixnetwork.SharedEntities.BannerAlerts.AlertType"];

const severityMap: Record<AlertType, AlertProps["severity"]> = {
  Danger: "error",
  Warning: "warning",
  Success: "success",
  Info: "info",
  Dark: "info",
  Default: "info",
  Light: "info",
  Primary: "info",
  Secondary: "info",
};

export function useGlobalAlertsBar() {
  const { data, isLoading, isError } = useCentrixApi({
    path: "/api/shared-entities/banner-alerts/{callerServiceName}",
    parameters: {
      path: { callerServiceName: "Pitstop" },
    },
  });
  const [dismissedAlerts, setDismissedAlerts] = useState<AlertMessage[]>([]);
  const filteredAlerts = useMemo<AlertMessage[]>(() => {
    if (isLoading || isError) {
      return [];
    }
    return (
      data?.items?.filter(
        (alertMessage) => !dismissedAlerts.includes(alertMessage)
      ) ?? []
    );
  }, [data, isLoading, isError, dismissedAlerts]);
  const onAlertClose = useCallback((alertMessage: AlertMessage) => {
    setDismissedAlerts((prev) => [...prev, alertMessage]);
  }, []);

  return { alerts: filteredAlerts, onAlertClose };
}

export function GlobalAlertsBar({
  alerts,
  onClose,
}: {
  alerts: AlertMessage[];
  onClose: (alert: AlertMessage) => void;
}) {
  if (alerts.length === 0) {
    return null;
  }
  return (
    <Container maxWidth="xl" sx={{ mt: 2 }} data-testid="global-alert-bar">
      {alerts.map((messageAlert) => (
        <Alert
          key={messageAlert.title}
          severity={severityMap[messageAlert?.type ?? "Info"]}
          onClose={() => {
            onClose(messageAlert);
          }}
        >
          {messageAlert.title && <AlertTitle>{messageAlert.title}</AlertTitle>}
          {messageAlert.message}
        </Alert>
      ))}
    </Container>
  );
}
