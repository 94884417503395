import { ALL_VALUE } from "appConfiguration";

export function filterDepartmentOptionsBySelectedBrands(
  selectedBrands: string[],
  departmentOptions: { label: string; value: string }[],
  brandsByDepartment: Record<string, string[]>
): { label: string; value: string }[] {
  if (selectedBrands.length === 0 || selectedBrands.includes(ALL_VALUE)) {
    return departmentOptions;
  }

  return departmentOptions.filter(({ value: departmentOptionValue }) => {
    if (departmentOptionValue === ALL_VALUE) {
      return true;
    }
    const departmentBrands = brandsByDepartment[departmentOptionValue];
    if (!departmentBrands) {
      return false;
    }
    return selectedBrands.some((selectedBrand) =>
      departmentBrands.includes(selectedBrand)
    );
  });
}
