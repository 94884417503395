import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";
import { useCentrixApi } from "core/hooks/useCentrixApi";
import { useDmsCategoryLibraryProcessor } from "./useDmsCategoryLibraryProcessor";
import { useLibraryQueryStateSorting } from "./useLibraryQueryStateSorting";

interface UseCategoriesOptions {
  parentId: string;
  shouldFetch?: boolean;
  mode: "view" | "manage";
  canManageLibraryContents: boolean | undefined;
}
export function useCategories({
  mode,
  parentId,
  canManageLibraryContents,
  shouldFetch = true,
}: UseCategoriesOptions) {
  const [queryStateSorting] = useLibraryQueryStateSorting();
  const { locale = "en" } = useRouter();

  const processCategory = useDmsCategoryLibraryProcessor();

  const {
    data: manageData,
    isLoading: isManageDataLoading,
    isError: isManageDataError,
    mutate: manageMutate,
  } = useCentrixApi({
    path: "/api/app/doc-category/manageable-list",
    parameters: {
      query: {
        ParentId: parentId,
        Sorting: queryStateSorting,
        Locale: locale,
      },
    },
    shouldFetch: canManageLibraryContents && shouldFetch,
    fetchAll: true,
  });

  const {
    data: viewData,
    isLoading: isViewDataLoading,
    isError: isViewDataError,
    mutate: viewMutate,
  } = useCentrixApi({
    path: "/api/app/doc-category/viewable-list",
    parameters: {
      query: {
        ParentId: parentId,
        Sorting: queryStateSorting,
        Locale: locale,
      },
    },
    shouldFetch,
    fetchAll: true,
  });

  const mutate = useCallback(
    async (
      data?: Parameters<typeof manageMutate>[0],
      opts?: Parameters<typeof manageMutate>[1]
    ) => {
      await viewMutate();
      if (data && opts) {
        await manageMutate(data, opts);
        return;
      }
      if (data) {
        await manageMutate(data);
        return;
      }
      await manageMutate();
    },
    [viewMutate, manageMutate]
  );

  const { data, isLoading, isError } = useMemo(() => {
    if (mode === "manage") {
      const { items, totalCount } = manageData ?? {};
      const processedItems = items?.map(processCategory) || [];
      return {
        data:
          isManageDataLoading || isManageDataError
            ? undefined
            : { items: processedItems, totalCount },
        isLoading: isManageDataLoading,
        isError: isManageDataError,
      };
    }

    const { items, totalCount } = viewData ?? {};
    const processedItems = items?.map(processCategory) || [];
    return {
      data:
        isViewDataLoading || isViewDataError
          ? undefined
          : { items: processedItems, totalCount },
      isLoading: isViewDataLoading,
      isError: isViewDataError,
    };
  }, [
    mode,
    processCategory,
    manageData,
    isManageDataLoading,
    isManageDataError,
    isViewDataError,
    isViewDataLoading,
    viewData,
  ]);

  return {
    data,
    isLoading,
    isError,
    mutate,
  };
}
