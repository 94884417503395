import getConfig from "next/config";

export {
  brandCodes,
  regionCodes,
  brandRegionCodes,
  stateCodes,
} from "./brandRegionConfig";

// ! REMEMBER TO UPDATE next.config.js WHEN ADDING publicRuntimeConfig VARIABLES
const { publicRuntimeConfig } = getConfig() || {};
export const environment = process.env.NODE_ENV;
export const isProduction = process.env.NODE_ENV === "production";
export const isDevelopment = process.env.NODE_ENV === "development";
export const isBrowser = typeof window !== "undefined";
export const isMSWEnabled =
  publicRuntimeConfig?.NEXT_PUBLIC_API_MOCKING === "enabled"; // default disabled

export const CENTRIX_URL =
  publicRuntimeConfig?.CENTRIX_URL || process.env.NEXT_PUBLIC_CENTRIX_URL;

export const API_URL =
  publicRuntimeConfig?.API_URL || process.env.NEXT_PUBLIC_API_URL;

export const IDS_URL =
  publicRuntimeConfig?.IDS_URL || process.env.NEXT_PUBLIC_IDS_URL;

export const APPLICATIONINSIGHTS_CONNECTION_STRING =
  publicRuntimeConfig?.APPLICATIONINSIGHTS_CONNECTION_STRING ||
  process.env.APPLICATIONINSIGHTS_CONNECTION_STRING;

export const GOOGLE_EMBED_API_KEY =
  publicRuntimeConfig?.GOOGLE_EMBED_API_KEY ||
  process.env.NEXT_PUBLIC_GOOGLE_EMBED_API_KEY;

export const SUPPORT_EMAIL =
  publicRuntimeConfig?.SUPPORT_EMAIL || process.env.NEXT_PUBLIC_SUPPORT_EMAIL;

export const NPS_REDIRECT_URL =
  publicRuntimeConfig?.NPS_REDIRECT_URL ||
  process.env.NEXT_PUBLIC_NPS_REDIRECT_URL;

export const V7_REDIRECT_URL =
  publicRuntimeConfig?.V7_REDIRECT_URL ||
  process.env.NEXT_PUBLIC_V7_REDIRECT_URL;

export const V7_PROC_REDIRECT_URL =
  publicRuntimeConfig?.V7_PROC_REDIRECT_URL ||
  process.env.NEXT_PUBLIC_V7_PROC_REDIRECT_URL;

export const V7_CRE_REDIRECT_URL =
  publicRuntimeConfig?.V7_CRE_REDIRECT_URL ||
  process.env.NEXT_PUBLIC_V7_CRE_REDIRECT_URL;

export const SPEEDYAUTO_REDIRECT_URL =
  publicRuntimeConfig?.SPEEDYAUTO_REDIRECT_URL ||
  process.env.NEXT_PUBLIC_SPEEDYAUTO_REDIRECT_URL;

export const NOVUSGLASS_REDIRECT_URL =
  publicRuntimeConfig?.NOVUSGLASS_REDIRECT_URL ||
  process.env.NEXT_PUBLIC_NOVUSGLASS_REDIRECT_URL;

export const FIXAUTO_REDIRECT_URL =
  publicRuntimeConfig?.FIXAUTO_REDIRECT_URL ||
  process.env.NEXT_PUBLIC_FIXAUTO_REDIRECT_URL;

export const NPS_CRE_REDIRECT_URL =
  publicRuntimeConfig?.NPS_CRE_REDIRECT_URL ||
  process.env.NEXT_PUBLIC_NPS_CRE_REDIRECT_URL;

export const NPS_FIXAUTO_REDIRECT_URL =
  publicRuntimeConfig?.NPS_FIXAUTO_REDIRECT_URL ||
  process.env.NEXT_PUBLIC_NPS_FIXAUTO_REDIRECT_URL;

export const REVALIDATE_IF_STALE =
  publicRuntimeConfig?.REVALIDATE_IF_STALE !== "false"; // default true

export const SANITIZE_CMS_CONTENT =
  publicRuntimeConfig?.SANITIZE_CMS_CONTENT !== "false"; // default true

export const MUI_X_LICENSE =
  publicRuntimeConfig?.NEXT_PUBLIC_MUI_X_LICENSE ||
  process.env.NEXT_PUBLIC_MUI_X_LICENSE;

export const RECAPTCHA_SITE_KEY =
  publicRuntimeConfig?.NEXT_PUBLIC_RECAPTCHA_SITE_KEY ||
  process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;

export const DEFAULT_DEV_BRAND_CODE =
  publicRuntimeConfig?.NEXT_PUBLIC_DEFAULT_DEV_BRAND_CODE ||
  process.env.NEXT_PUBLIC_DEFAULT_DEV_BRAND_CODE;

export const AUTHORIZE_DOTNET_CLIENT_KEY =
  publicRuntimeConfig?.NEXT_PUBLIC_AUTHORIZE_DOTNET_CLIENT_KEY ||
  process.env.NEXT_PUBLIC_AUTHORIZE_DOTNET_CLIENT_KEY;

export const AUTHORIZE_DOTNET_API_LOGIN_ID =
  publicRuntimeConfig?.NEXT_PUBLIC_AUTHORIZE_DOTNET_API_LOGIN_ID ||
  process.env.NEXT_PUBLIC_AUTHORIZE_DOTNET_API_LOGIN_ID;

export const AUTHORIZE_DOTNET_ENVIRONMENT =
  publicRuntimeConfig?.NEXT_PUBLIC_AUTHORIZE_DOTNET_ENVIRONMENT ||
  process.env.NEXT_PUBLIC_AUTHORIZE_DOTNET_ENVIRONMENT;

export const ROYALTY_PAYMENT_SHOW_LOCATION =
  publicRuntimeConfig?.NEXT_PUBLIC_ROYALTY_PAYMENT_SHOW_LOCATION === "true" ||
  process.env.NEXT_PUBLIC_ROYALTY_PAYMENT_SHOW_LOCATION === "true";

export const ROYALTY_PAYMENT_ENABLED =
  publicRuntimeConfig?.NEXT_PUBLIC_ROYALTY_PAYMENT_ENABLED === "true" ||
  process.env.NEXT_PUBLIC_ROYALTY_PAYMENT_ENABLED === "true";

export const HTML_EDITOR_IMAGE_UPLOAD_ENABLED =
  publicRuntimeConfig?.NEXT_PUBLIC_HTML_EDITOR_IMAGE_UPLOAD_ENABLED ===
    "true" ||
  process.env.NEXT_PUBLIC_HTML_EDITOR_IMAGE_UPLOAD_ENABLED === "true";

export const ALL_VALUE = "ALL" as const;
export const EMPTY_VALUE = " - " as const;
export const MAX_MAX_RESULT_COUNT = 1000 as const;

export const BRAND_FILTER_QUERY_KEY = "brandFilter" as const;
export const REGION_FILTER_QUERY_KEY = "regionFilter" as const;
export const SEARCH_FILTER_QUERY_KEY = "search" as const;

// ! MUST BE EQUAL TO (next.config.js).i18n.locales
export const i18nLocales = ["en", "fr", "es", "de-DE"] as const;

export type SupportedLocales = (typeof i18nLocales)[number];

export function validateConfigServerSide() {
  if (isBrowser) {
    return;
  }
  const supportedLocales = getConfig()?.serverRuntimeConfig
    ?.I18N_SUPPORTED_LOCALES as string[] | undefined;
  if (!supportedLocales) {
    // eslint-disable-next-line no-console
    console.error("I18N_SUPPORTED_LOCALES is not defined in next.config.js");
    process.exit(1);
  }
  const missingLocales = supportedLocales.filter(
    (locale) => !i18nLocales.includes(locale as any)
  );
  if (missingLocales.length) {
    // eslint-disable-next-line no-console
    console.error(
      `config.ts: i18nLocales missing: ${missingLocales.join(", ")}`
    );
    process.exit(1);
  }
  const missingNextConfigLocales = i18nLocales.filter(
    (locale) => !supportedLocales.includes(locale)
  );
  if (missingNextConfigLocales.length) {
    // eslint-disable-next-line no-console
    console.error(
      `next.config.js: supportedLocales missing: ${missingNextConfigLocales.join(
        ", "
      )}`
    );
    process.exit(1);
  }
}
